import React from "react"
import { Link } from "gatsby"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

import Layout from "../../../components/bs/layout"
//import Image from "../components/image"
import SEO from "../../../components/bs/seo"

import MainImg from "./../../../images/main_bg.jpg"

import shape1 from "./../../../images/dec/1.png"
import shape2 from "./../../../images/dec/2.png"
import shape3 from "./../../../images/dec/3.png"
import shape4 from "./../../../images/dec/4.png"
import shape5 from "./../../../images/dec/5.png"
import shape6 from "./../../../images/dec/6.png"
import shape7 from "./../../../images/dec/7.png"
import shape8 from "./../../../images/dec/8.png"

import product1 from "./../../../images/moonamar-hladno-cijedeno-ulje-corokota-crnog-kima.png"
import product2 from "./../../../images/moonamar-hladno-cijedeno-bundevino-bucino-ulje.png"
import product3 from "./../../../images/moonamar-konopljino-ulje-iz-organskog-bio-sjemena.png"
import product4 from "./../../../images/moonamar-hladno-cijedeno-djevicansko-laneno-ulje.png"

const CorokotovoUlje = () => (
  <Layout>
    <SEO
      title="MOONAMAR - HLADNO CIJEĐENO ULJE ČOROKOTA (CRNOG KIMA)"
      description="MOONAMAR hladno cijeđeno ulje čorokota - prirodno ulje koje se ne izlaže nikakvom dodatnom procesiranju."
    />

    <section
      id="_heroPage"
      className="hero is-large"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body" />
    </section>

    <span className="_line" />

    <section id="_oProizvodu" className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="_slide">
              <img
                className="_img"
                src={product1}
                title="HLADNO CIJEĐENO ULJE ČOROKOTA (CRNOG KIMA)"
                alt="HLADNO CIJEĐENO ULJE ČOROKOTA (CRNOG KIMA)"
              />
              <div className="_info">
                <div className="_inner">
                  <span>MOONAMAR</span>
                  <div className="title">Čorokotovo ulje</div>
                  <div className="subtitle">
                    Inspirisano tradicijom velikih religija i kultura
                  </div>
                  <p className="content">
                    Slab imunitet čini tijelo podložno infekcijama a
                    neuravnotežen imunitet dovodi do alergijskih oboljenja.
                    Čorokot djeluje blagotvorno pri balansiranju imuniteta kao
                    temelja održavanja zdravlja.
                  </p>
                  <p className="content">
                    Tradicionalno se najčešće koristi protiv alergija i kao
                    sredstvo za jačanje imuniteta.
                  </p>
                  <div className="price">22,90 KM</div>
                </div>
                <div className="_buttonShop">
                  <a href="https://zdravo.ba/proizvod/zdravlje/moonamar/corokotovo-ulje-hladno-cijedjeno-nefiltrirano">
                    KUPI PROIZVOD
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="_pageContent" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <Tabs>
              <TabList>
                <Tab>Opis proizvoda</Tab>
                <Tab>Sastojci i upotreba</Tab>
                <Tab>Prirodni talenat</Tab>
              </TabList>

              <TabPanel>
                <div className="content">
                  <p>
                    <strong>PROCES CIJEĐENJA</strong>: hladno cijeđenje <br />{" "}
                    <strong>KVALITET</strong>: ekstra djevičansko <br />{" "}
                    <strong>VRSTA SJEMENA</strong>: Nigella Sativa
                    <br /> <strong>PORIJEKLO SJEMENA</strong>: Egipat&nbsp;{" "}
                    <br /> <strong>NAČIN PROIZVODNJE</strong>: Prirodan bez
                    dodatnog procesiranja <br />{" "}
                    <strong>DODACI PREPARATU</strong>: bez ikakvih dodatnih
                    sastojaka, 100% čorokot
                    <br />
                    <strong>STABILNOST</strong>: čorokotovo ulje je zahvaljujući
                    svom sastavu veoma stabilno i rezistentno na kontaminacije.
                    Ne preporučuje se upotreba za kuhanje i prženje
                    <br /> <strong>SKLADI&Scaron;TENJE</strong>: preporučuje se
                    skladi&scaron;tenje na hladnom mjestu.
                  </p>
                  <p>
                    MOONAMAR čorokotovo ulje dobija se procesom hladnog
                    cijeđenja, ne filtrira se i nije izloženo dodatnom
                    procesiranju i to sve u cilju očuvanja korisnih supstanci
                    sadržanih u sjemenu.
                  </p>
                  <p>
                    Iz tog razloga se na dnu fla&scaron;e pojavljuje talog od
                    dijelova sjemenki čorokota. Preporučujemo lagano mućkanje
                    boce prije svake upotrebe da bi se ovaj talog, koji je zbog
                    visoke koncentracije &nbsp;vlakana, vrlo koristan za
                    probavni sistem, pomije&scaron;ao sa uljem. Ovim pojačavate
                    učinak MOONAMAR čorokotovog ulja.
                  </p>
                  <p>
                    Iznimno rijetko se javljaju osobe koje ga ne podnose i to
                    uglavnom zbog njegovog neuobičajenog okusa. Iz tog razloga
                    na&scaron; tim vodi računa kako o kvaliteti tako i o okusu
                    ulja.{" "}
                    <strong>
                      MOONAMAR čorokotovo ulje ima izuzetno blag okus
                      zahvaljujući posebno odabranoj vrsti sjemena, porijeklom
                      iz Egipta, i specijalnom procesu cijeđenja.
                    </strong>
                  </p>
                  <p>
                    Kao i kod većine prirodnih proizvoda, MOONAMAR čorokotovo
                    ulje je potrebno redovno konzumirati, a prve pozitivne
                    efekte na zdravlje možete očekivati, nakon 4 do 8 sedmica
                    redovne upotrebe.
                  </p>
                  <table>
                    <tbody>
                      <tr>
                        <td width="125">
                          <p>
                            <strong>Nutritivne vrijednosti</strong>
                          </p>
                        </td>
                        <td width="67">
                          <p>
                            <strong>na 100 g</strong>
                          </p>
                        </td>
                        <td width="81">
                          <p>
                            <strong>na 5 g</strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Kalorijska vrijednost</p>
                        </td>
                        <td>
                          <p>
                            3.696 kJ
                            <br /> 899 kcal
                          </p>
                        </td>
                        <td>
                          <p>
                            184,8 kJ
                            <br /> 44,95 kcal
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            Masnoće
                            <br />
                            <em>od kojih zasićene</em>
                          </p>
                        </td>
                        <td>
                          <p>
                            99,9 g<br /> 15,9 g
                          </p>
                        </td>
                        <td>
                          <p>
                            4,99 g<br /> 0,79 g
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            Ugljikohidrati
                            <br />
                            <em>od kojih &scaron;ećeri</em>
                          </p>
                        </td>
                        <td>
                          <p>
                            &lt; 0,1 g<br /> &lt; 0,5 g
                          </p>
                        </td>
                        <td>
                          <p>
                            &lt; 0,005 g<br /> &lt; 0,025 g
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Bjelančevine</p>
                        </td>
                        <td>
                          <p>0,1 g</p>
                        </td>
                        <td>
                          <p>0,005 g</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Sol</p>
                        </td>
                        <td>
                          <p>&lt; 0,0025 g</p>
                        </td>
                        <td>
                          <p>&lt; 0,000125 g</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="content">
                  <h3>SASTOJCI</h3>
                  <p>
                    Čorokotovo ulje je bogato raznoraznim vitaminima,
                    mineralima, proteinima, nezasićenim masnim kiselinama,
                    fitosterolima, zatim aktivnim supstancama koje se mogu naći
                    samo u čorokotu kao &scaron;to su Nigellon, Timokinon i
                    Nigellin, raznim eteričnim uljima, saponinima itd.
                  </p>
                  <h3>UPOTREBA</h3>
                  <p>
                    MOONAMAR čorokotovo ulje dobija se procesom hladnog
                    cijeđenja, ne filtrira se i nije izloženo dodatnom
                    procesiranju i to sve u cilju očuvanja korisnih supstanci
                    sadržanih u sjemenu.
                  </p>
                  <p>
                    Iz tog razloga preporučujemo lagano mućkanje boce prije
                    svake upotrebe da bi se talog sačinjen od nefiltriranih
                    dijelova sjemena, koji je zbog visokog postotka vlakana vrlo
                    koristan za probavni sistem, pomije&scaron;ao sa uljem. Ovim
                    pojačavate učinak MOONAMAR čorokotovog ulja.
                  </p>
                  <p>
                    <strong>Za jačanje imuniteta:</strong>
                    <br /> <strong>Odrasli:</strong> 2-3 puta dnevno 1 supena
                    ka&scaron;ika, po mogućnosti neposredno prije ili uz
                    umjerene obroke.
                    <br /> <strong>Djeca:</strong> 2-3 puta dnevno 1 mala
                    ka&scaron;ika
                    <br /> <br /> Preventivno, u cilju održavanja jakog
                    imuniteta se uzima jedna supena ka&scaron;ika dnevno prije
                    doručka (djeca uzimaju jednu čajnu ka&scaron;iku).
                    Preventiva je preporučljiva naročito u zimskom periodu i
                    situacijama u kojima smo izloženi stresu (stres utiče na
                    slabljenje imuniteta).
                    <br />{" "}
                    <em>
                      Ulje treba uzimati najmanje 8 sedmica da bi se osjetili
                      prvi efekti na imunitet, a preporučljivo je dugoročnije
                      uzimanje naročito kod ljudi izloženih permanentnom stresu
                      i sa sklonostima nepravilnoj ishrani.
                    </em>
                  </p>
                  <p>
                    <strong>Za alergije</strong>
                    <br /> Radilo se o alergijama na hranu ili vanjske faktore
                    poput polena, pra&scaron;ine, mačije dlake ili sl.
                    preporučujemo sljedeći način upotrebe:
                  </p>
                  <p>
                    3 x dnevno 1 supena ka&scaron;ika, najbolje neposredno prije
                    jela, ili uz jelo. Obratite pažnju da ćete uz umjerene
                    obroke povećati učinkovitost čorokotovog ulja.
                  </p>
                  <p>
                    Ukoliko se radi o sezonskim alergijama, kao &scaron;to je
                    alergija na polen, predlažemo da započnete nekoliko mjeseci
                    prije sezone u kojoj se javlja Va&scaron;a alergija.
                  </p>
                  <p>
                    Eventualno pojavljivanje žgaravice vas ne treba brinuti, jer
                    ona već nakon par dana kori&scaron;tenja nestaje.
                  </p>
                  <p>
                    <strong>
                      Za osnovnu potporu imunolo&scaron;kom sustavu i
                      metabolizmu
                    </strong>
                  </p>
                  <p>
                    <strong>
                      Po&scaron;to je čorokot (crni kim) izuzetno bogat
                      raznoraznim korisnim sastojcima koji su često zanemareni u
                      svakodnevnoj ishrani, od raznih vitaminima preko minerala
                      pa do esencijalnih nezasićenih masnih kiselina,
                      preporučuje se kao dodatak svakodnevnoj ishrani u cilju
                      njenog upotpunjavanja.{" "}
                    </strong>
                  </p>
                  <p>
                    <strong>
                      Preporučena upotreba:
                      <br /> Odrasli: 1 supena ka&scaron;ika dnevno
                      <br /> Djeca: 1 mala ka&scaron;ika dnevno
                    </strong>
                  </p>
                  <p>
                    <strong>Napomena</strong>
                    <br /> Čorokotovo ulje nema naučno utvrđenih sporednih
                    efekata i ne prouzrokuje alergijske reakcije, ali se ipak iz
                    predostrožnosti ne preporučuje trudnicama, bebama i osobama
                    sa transplantiranim organima. Iznimno rijetko se javljaju
                    osobe koje ga ne podnose i to uglavnom zbog njegovog
                    neuobičajenog okusa.
                    <br /> Iz tog razloga na&scaron; tim vodi računa kako o
                    kvaliteti tako i o okusu ulja. MOONAMAR čorokotovo ulje ima
                    izuzetno blag okus zahvaljujući posebno odabranoj vrsti
                    sjemena i specijalnom procesu cijeđenja.
                  </p>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="content">
                  <p>
                    <strong>
                      Nevjerovatno izgleda da jedna tako mala sjemenka može u
                      toj mjeri obilovati raznoraznim aktivnim supstancama, kao
                      &scaron;to je slučaj sa čorokotom. Ako bismo na osnovu
                      iskustava iz tradicionalne medicine i najnovijih rezultata
                      moderne nauke, od desetina hiljada prirodnih preparata
                      pravili ekskluzivni izbor onih sa naj&scaron;irim spektrom
                      blagotvornih dejstava, čorokotovo ulje bi se zasigurno
                      na&scaron;lo na vrhu ove liste.
                    </strong>
                  </p>
                  <h3>Talenat za balansiranje i jačanje imunog sistema</h3>
                  <p>
                    Čorokot svoje dejstvo proteže prvenstveno kroz vraćanje
                    ravnoteže u imunolo&scaron;ki i metabolički sistem koji čine
                    osnovu za uspostavljanje i održavanje zdravlja. Kaže se da
                    za svaku bolest u prirodi postoji lijek, ali ipak najveći
                    saveznik u ozdravljenju i održavanju zdravlja je na&scaron;
                    vlastiti organizam. Ulje čorokota (crnog kima) upotpunjava
                    potrebe na&scaron;eg tijela te mu pomaže da se izbori protiv
                    mnogobrojnih negativnih posljedica modernog i ubrzanog
                    života koje &scaron;tete toj finoj ravnoteži organizma
                    toliko potrebnoj za zdravlje.
                  </p>
                  <p>
                    Generalno, čorokotovo ulje se može primjenjivati kako
                    preventivno tako i kao potpora u slučaju zdravstvenih
                    tegoba. I u jednom i u drugom slučaju se njegovi efekti
                    prvenstveno protežu putem jačanja i harmoniziranja imunog
                    sistema i opskrbljavanja organizma supstancama potrebnim za
                    besprijekorno odvijanje biohemijskih metaboličkih procesa.
                    Na ovaj način se stvara podloga da organizam jača i biva
                    sposoban da se sam brani od bolesti ili liječi od već
                    postojeće bolesti.{" "}
                    <strong>
                      Ulje čorokota (crnog kima) &ldquo;pametno&rdquo; djeluje
                      na imunitet.
                    </strong>{" "}
                    Pomaže pri njegovom stimuliranju u slučaju slabog imuniteta
                    a u slučajevima alergije pomaže pri reguliranju njegove
                    pretjerane reakcije.
                  </p>
                  <p>
                    Osim ovog dugoročnog i indirektnog puta ostvarivanja
                    blagotvornih dejstava čorokot ima i neposredne, direktne
                    efekte kao &scaron;to su, primjera radi, bronhodilatorni
                    efekat (efekat pro&scaron;irivanja bronhija, koristan za
                    astmatičare) putem supstance Nigellon ili efekat ublažavanja
                    simptoma alergijskih reakcija putem supstanci sa
                    antihistaminskim dejstvom, veoma koristan kod alergija na
                    hranu (histamin je jedan od medijatora alergijskih
                    reakcija).
                  </p>
                  <p>
                    <strong>
                      Zbog svog imunomodulatornog potencijala se sve
                      če&scaron;će istražuje i njegova blagotvornost u pogledu
                      autoimunolo&scaron;kih oboljenja.
                    </strong>
                  </p>
                  <h3>Talenat za popravljanje krvne slike</h3>
                  <p>
                    Zahvaljujući sastavu bogatom nezasićenim masnim kiselinama
                    &ndash; linolenska (Omega 3), linolna (Omega 6) i oleinska,
                    čorokot pozitivno utiče na održavanje zdravog nivoa lipida
                    (masnoća) u krvi a pomaže i pri regulaciji
                    zgru&scaron;avanja krvi i normaliziranju krvnog pritiska.
                    Ove masne kiseline istovremeno ublažavaju upalne procese u
                    slučaju infekcije ili upale te reguli&scaron;u rad
                    imunolo&scaron;kog sistema, neophodne su za zdravlje, pomažu
                    kod regeneracije kože, sprječavaju rano starenje, pomažu kod
                    gubitka tjelesne težine i razgradnje holesterola pri čemu se
                    smanjuju naslage holesterola u arterijama.
                  </p>
                  <h3>Antiastmatični talenat&nbsp;</h3>
                  <p>
                    Kod astme su korisni specifični sastojci poput Nigellona
                    koji opu&scaron;ta mi&scaron;iće i &scaron;iri bronhije,
                    gama-linoleinske kiseline, putem koje tijelo proizvodi
                    protiv-upalnu i protiv-alergijsku supstancu prostaglandin E1
                    (PGE1) i arahidonske kiseline koja služi kao gradivni
                    materijal za proizvodnju prostaglandina E2 (PGE2) koji
                    pokazuje bronho-opu&scaron;tajuće delovanje, te ima i
                    terapeutsko delovanje kod alergijske astme.
                  </p>
                  <h3>Svakodnevni stres i upotpunjavanje ishrane</h3>
                  <p>
                    U dana&scaron;nje vrijeme mnogi upadaju u raznorazne stresne
                    situacije zbog čega im veoma te&scaron;ko pada održavanje
                    duhovnog i emotivnog balansa &scaron;to se izuzetno
                    negativno ogleda na imunitet, a op&scaron;te je poznato da
                    stres slabi imunitet.
                    <br /> Osim toga je i kvalitet ishrane modernog čovjeka
                    uveliko srozan a svaki organizam ima svoje biohemijske
                    prohtjeve koji se upotpunjuju ishranom i koji su uslov za
                    održavanje intaktnog imuniteta. Nažalost kvalitet hrane je
                    iz godine u godinu također sve slabiji tako da ne treba da
                    iznenađuje masa dodataka ishrani u apotekama i
                    supermarketima. Jedan od prirodnih dodataka ishrani koji
                    upotpunjuje jedan veliki broj biohemijskih potreba organizma
                    je i čorokot / čurekot (crni kim).
                  </p>
                  <h3>Ostali talenti</h3>
                  <p>
                    Timokinon je jedna za čorokot karakteristična supstanca sa
                    efektom na pojačavanje lučenja žuči, ublažavanje kiselosti,
                    smanjivanje nivoa holesterola kao i probleme sa probavom.
                  </p>
                  <p>
                    Dokazano je blagotvorno dejstvo crnog kima i na
                    dermatolo&scaron;ke probleme: ekcem, kožne gljivice, akne.
                    Ovo dejstvo je uzrokovano kako snažnim antibakterijskim
                    dejstvom čorokota i već spomenutim antiupalnim supstancama
                    tako i raznoraznim mineralima i vitaminima koji
                    pospje&scaron;uju regeneraciju kože.
                  </p>
                  <p>
                    <strong>
                      Svojstva čorokota su jedna veoma &scaron;iroka tema koju
                      je te&scaron;ko sažeti u jednoj knjizi a kamoli u jednom
                      informativnom tekstu. U svakom slučaju bitno je znati da
                      su njegova blagotvorna dejstva mnogobrojna i da se protežu
                      zahvaljujući &scaron;irokom spektru aktivnih supstanci
                      sadržanih u sjemenu čorokota i kao njihovih međusobnih
                      sinergijskih dejstava.
                    </strong>
                  </p>
                  <p>
                    <strong>Pogledaj vi&scaron;e o čorokotu na </strong>
                    <a href="http://www.modroizeleno.com">
                      <strong>www.modroizeleno.com</strong>
                    </a>{" "}
                  </p>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>

    <section id="_listProduct" className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <nav className="level">
              <div className="level-item has-text-centered">
                <div>
                  <Link to="/bs/proizvod/corokotovo-ulje">
                    <figure className="image is-4by5">
                      <img
                        src={product1}
                        alt="HLADNO CIJEĐENO ULJE ČOROKOTA (CRNOG KIMA)"
                        title="HLADNO CIJEĐENO ULJE ČOROKOTA (CRNOG KIMA)"
                      />
                    </figure>
                  </Link>
                </div>
              </div>
              <div className="level-item has-text-centered">
                <div>
                  <Link to="/bs/proizvod/bundevino-ulje">
                    <figure className="image is-4by5">
                      <img
                        src={product2}
                        alt="HLADNO CIJEĐENO BUNDEVINO (BUĆINO) ULJE"
                        title="HLADNO CIJEĐENO BUNDEVINO (BUĆINO) ULJE"
                      />
                    </figure>
                  </Link>
                </div>
              </div>
              <div className="level-item has-text-centered">
                <div>
                  <Link to="/bs/proizvod/konopljino-ulje">
                    <figure className="image is-4by5">
                      <img
                        src={product3}
                        alt="KONOPLJINO ULJE IZ ORGANSKOG (BIO) SJEMENA"
                        title="KONOPLJINO ULJE IZ ORGANSKOG (BIO) SJEMENA"
                      />
                    </figure>
                  </Link>
                </div>
              </div>
              <div className="level-item has-text-centered">
                <div>
                  <Link to="/bs/proizvod/laneno-ulje">
                    <figure className="image is-4by5">
                      <img
                        src={product4}
                        alt="HLADNO CIJEĐENO, DJEVIČANSKO LANENO ULJE"
                        title="HLADNO CIJEĐENO, DJEVIČANSKO LANENO ULJE"
                      />
                    </figure>
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default CorokotovoUlje
